import {defineStore} from 'pinia';
import type {AxiosResponse} from 'axios';
import api from '@/api/pluginStore';
import {useAppStore} from '@/stores/app';
import type {ReviewStats} from "~";

export interface Developer {
  developerName: string;
  developerUrl: string | null;
  location: string | null;
  id: number;
  slug: string;
  title: string;
  username: string;
  fullName: string;
  email: any;
  photoUrl: string;

  partnerInfo?: {
    profileUrl: string;
    isCraftVerified: boolean;
    isCommerceVerified: boolean;
    isEnterpriseVerified: boolean;
  };
}

export interface SortOptions {
  [key: string]: 'asc' | 'desc';
}

export interface Category {
  description: string | null;
  iconUrl: string;
  id: number;
  slug: string;
  title: string;
}

export interface PluginEdition {
  id: number;
  name: string;
  handle: string;
  price: number | null;
  renewalPrice: number | null;
  features: Array<any>;
}

export type License = string; // @todo should be an enum
export type MinCmsEdition = string; // @todo shoudl be an enum
export interface InstallHistoryItem {
  activeInstalls: number;
  date: string;
}

export interface IssueStats {
  closedIssues: number;
  mergedPulls: number;
  openIssues: number;
  openPulls: number;
  period: number;
}

export interface Plugin {
  iconUrl: string | null;
  name: string;
  handle: string;
  developerSlug: string | null;
  developerName: string | null;
  shortDescription: string;
  editions: Array<PluginEdition>;
  compatibility: 'Craft 3' | 'Craft 4' | 'Craft 5';
  categoryIds: Array<number>;
  phpConstraint: string | null;
  status: 'enabled';
  iconId: string | null;
  longDescription: string;
  documentationUrl: string | null;
  changelogUrl: string | null;
  repository: string | null;
  license: License;
  minCmsEdition: MinCmsEdition;
  developerUrl: string | null;
  screenshotUrls: string[];
  thumbnailUrls: string[];
  screenshotIds: number[];
  replacementName: string | null;
  replacementHandle: string | null;
  installHistory: InstallHistoryItem[];
  issueStats: IssueStats[];
  phpVersionCompatible: boolean;
  abandoned: boolean;
  note: string;
  reviewStats: ReviewStats
}


export const usePluginStoreStore = defineStore('pluginStore', {
  state: () => ({
    categories: [] as Category[],
    sortOptions: {} as SortOptions,
    abandonmentReasons: [] as Array<{value: string; label: string}>,

    // developer details
    developer: null as Developer | null,

    // plugin details
    plugin: null as Plugin | null,
    pluginDetailsCmsConstraint: null as string | null,
    pluginChangelog: null,
    pluginChangelogPluginId: null as number | null,
    pluginChangelogCmsConstraint: null as string | null,

    // featured sections
    featuredSections: [] as Array<any>,
    featuredSection: null as any,
  }),

  getters: {
    getCategoryBySlug(state) {
      return (slug: string) => {
        return state.categories.find((c) => c.slug === slug);
      };
    },
    isCommercial() {
      return (plugin: Plugin) => {
        return !!plugin.editions.find(
          (edition) => edition.price && edition.price > 0,
        );
      };
    },
    getPluginEditions() {
      return (plugin: Plugin) => {
        return plugin.editions;
      };
    },
    isPluginEditionFree() {
      return (edition: PluginEdition) => {
        return edition.price === null;
      };
    },
  },
  actions: {
    async getCoreData() {
      const response = await api.getCoreData();
      this.updateCoreData({response});
    },
    async getDeveloperBySlug(slug: string) {
      const response: {data: Developer} = await api.getDeveloperBySlug(slug);
      this.receiveDeveloper({developer: response.data});
    },
    async getFeaturedSectionByHandle(featuredSectionHandle: string) {
      const response = await api.getFeaturedSectionByHandle(
        featuredSectionHandle,
      );
      this.updateFeaturedSection(response.data);
    },
    async getFeaturedSections() {
      const appStore = useAppStore();
      const response = await api.getFeaturedSections({
        cmsConstraint: appStore.cmsConstraint,
      });
      this.updateFeaturedSections(response.data);
    },
    async getPluginChangelog(pluginId: number) {
      const appStore = useAppStore();
      const response = await api.getPluginChangelog({
        cmsConstraint: appStore.cmsConstraint,
        pluginId,
      });
      this.updatePluginChangelog({
        pluginId,
        changelog: response.data,
        cmsConstraint: appStore.cmsConstraint,
      });
    },

    async getPluginDetails(pluginId: number) {
      const appStore = useAppStore();
      const response = await api.getPluginDetails(pluginId);
      this.updatePluginDetails({
        plugin: response.data,
        cmsConstraint: appStore.cmsConstraint,
      });
    },

    async getPluginDetailsByHandle(pluginHandle: string) {
      const appStore = useAppStore();
      const response = await api.getPluginDetailsByHandle({
        cmsConstraint: appStore.cmsConstraint,
        pluginHandle,
      });

      if (response.data && response.data.error) {
        throw response.data.error;
      }

      this.updatePluginDetails({
        plugin: response.data,
        cmsConstraint: appStore.cmsConstraint,
      });
    },

    cancelRequests() {
      return api.cancelRequests();
    },
    updateCoreData({response}: {response: AxiosResponse<any>}) {
      this.categories = response.data.categories;
      this.sortOptions = response.data.sortOptions;
      this.abandonmentReasons = response.data.abandonmentReasons;
    },
    updatePluginDetails(context: {plugin: any; cmsConstraint: string} | null) {
      if (context && context.plugin && context.cmsConstraint) {
        this.plugin = context.plugin;
        this.pluginDetailsCmsConstraint = context.cmsConstraint;
      } else {
        this.plugin = null;
        this.pluginDetailsCmsConstraint = null;
      }
    },
    updatePluginChangelog({
      pluginId,
      changelog,
      cmsConstraint,
    }: {
      pluginId: number | null;
      changelog: any;
      cmsConstraint: string | null;
    }) {
      this.pluginChangelogPluginId = pluginId;
      this.pluginChangelog = changelog;
      this.pluginChangelogCmsConstraint = cmsConstraint;
    },
    receiveDeveloper({developer}: {developer: Developer}) {
      this.developer = developer;
    },
    updateFeaturedSection(featuredSection: any) {
      this.featuredSection = featuredSection;
    },
    updateFeaturedSections(featuredSections: any) {
      this.featuredSections = featuredSections;
    },
  },
});
