<template>
  <nuxt-link v-bind="cAttrs">
    <slot />
  </nuxt-link>
</template>

<script setup lang="ts">
  import {useAppStore, computed, useAttrs} from '#imports';

  const appStore = useAppStore();
  const $attrs = useAttrs();

  // Computed
  // const cmsConstraint = computed(() => appStore.cmsConstraint)
  const getCmsConstraintQuery = computed(() => appStore.getCmsConstraintQuery);
  const cAttrs = computed(() => {
    const attrs = Object.assign({}, $attrs);
    const newTo = addCmsVersionToQueryParams(attrs.to);

    return {
      ...$attrs,
      to: newTo,
    };
  });

  // Methods
  const addCmsVersionToQueryParams = (to) => {
    if (typeof to === 'string') {
      return {
        path: to,
        query: getCmsConstraintQuery.value(),
      };
    } else if (typeof to === 'object') {
      if (to.query) {
        if (!to.query.cmsConstraint) {
          to.query = {
            ...to.query,
            ...getCmsConstraintQuery.value(),
          };
        }
      } else {
        to.query = getCmsConstraintQuery.value();
      }

      return to;
    }
  };
</script>

<script lang="ts">
  export default {
    inheritAttrs: false,
  };
</script>
