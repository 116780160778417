import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import cms_45constraint_45global from "/vercel/path0/src/middleware/cms-constraint.global.js";
import hide_45navigation_45global from "/vercel/path0/src/middleware/hide-navigation.global.js";
import search_45global from "/vercel/path0/src/middleware/search.global.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cms_45constraint_45global,
  hide_45navigation_45global,
  search_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}