<template>
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="star-sharp"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.19 295.3 0 288 0S273.4 4.19 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.553 0 7.227-1.09 10.62-3.556L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM370.8 317.9l45.34 139.7l-118.7-86.3c-5.61-4.079-13.21-4.079-18.82 0l-118.7 86.3l45.34-139.7c2.139-6.589-.2054-13.81-5.808-17.88L80.7 213.6h146.8c6.933 0 13.08-4.465 15.22-11.06L288 62.96l45.33 139.6c2.141 6.594 8.285 11.06 15.22 11.06h146.8l-118.7 86.35C370.1 304.1 368.6 311.3 370.8 317.9z"
    />
  </svg>
</template>
