import Accounting from 'accounting';
import {defineNuxtPlugin} from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.globalProperties.$filters = {
    /**
     * Formats a value as a currency value.
     */
    currency(value) {
      let precision = 2;
      const floatValue = parseFloat(value);

      // Auto precision
      if (Math.round(floatValue) === floatValue) {
        precision = 0;
      }

      return Accounting.formatMoney(floatValue, '$', precision);
    },

    /**
     * Translate filter.
     */
    t(value) {
      return value;
    },

    /**
     * FormatNumber.
     */
    formatNumber(number, precision, thousand, decimal) {
      return Accounting.formatNumber(number, precision, thousand, decimal);
    },
  };
});
