import {defineNuxtRouteMiddleware, useAppStore} from '#imports';

export default defineNuxtRouteMiddleware((to) => {
  const appStore = useAppStore();

  let cmsConstraint = null;

  if (to.query) {
    cmsConstraint = appStore.getCmsConstraintFromQuery(to.query);
  }

  if (cmsConstraint) {
    appStore.setCmsConstraint(cmsConstraint);
  }
});
