import {defineNuxtRouteMiddleware, useAppStore} from '#imports';

export default defineNuxtRouteMiddleware(() => {
  const appStore = useAppStore();

  appStore.cancelRequests();

  if (import.meta.server) {
    return;
  }

  appStore.hideNavigation();
});
