<template>
  <input
    class="rounded-md bg-white dark:bg-gray-700 border border-field border-gray-200 dark:border-gray-600"
    type="text"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    @blur="$emit('blur', $event.target.value)"
    @focus="$emit('focus', $event.target.value)"
  />
</template>

<script setup lang="ts">
  import {toRefs} from '#imports';

  defineEmits(['update:modelValue', 'blur', 'focus']);

  const props = withDefaults(
    defineProps<{
      modelValue?: number | string;
    }>(),
    {
      modelValue: '',
    },
  );

  const {modelValue} = toRefs(props);
</script>
