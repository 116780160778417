import {defineNuxtRouteMiddleware, useAppStore, navigateTo} from '#imports';

export default defineNuxtRouteMiddleware((to) => {
  const appStore = useAppStore();

  if (to.path === '/search' && !to.query.q) {
    navigateTo('/');
  }

  if (to.path === '/search' && to.query.q) {
    appStore.updateSearchQuery(to.query.q);
  }
});
