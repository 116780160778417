export function useEnv() {
  const appUrl = import.meta.env.VITE_APP_URL
    ? import.meta.env.VITE_APP_URL
    : 'https://plugins.craftcms.com';
  const craftIdUrl = import.meta.env.VITE_CRAFT_ID_URL
    ? import.meta.env.VITE_CRAFT_ID_URL
    : 'https://console.craftcms.com';
  const apiEndpointUrl = import.meta.env.VITE_CRAFT_API_ENDPOINT
    ? import.meta.env.VITE_CRAFT_API_ENDPOINT
    : 'https://api.craftcms.com/v1';
  const showSeoMeta = import.meta.env.VITE_SHOW_SEO_META
    ? import.meta.env.VITE_SHOW_SEO_META
    : false;
  const craftConsoleUrl =
    import.meta.env.VITE_CRAFT_CONSOLE_URL || 'https://console.craftcms.com';

  function getPluginReviewUrl(handle) {
    if (!handle) {
      return '';
    }

    return `${craftConsoleUrl}/accounts/me/plugin-store/reviews/${handle}`;
  }

  return {
    appUrl,
    craftIdUrl,
    apiEndpointUrl,
    showSeoMeta,
    craftConsoleUrl,
    getPluginReviewUrl,
  };
}
