import CBtn from '../components/ui/CBtn.vue';
import CDropdown from '../components/ui/CDropdown.vue';
import CIcon from '../components/ui/CIcon.vue';
import CSpinner from '../components/ui/CSpinner.vue';
import CTextbox from '../components/ui/CTextbox.vue';
import {defineNuxtPlugin} from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('CBtn', CBtn);
  nuxtApp.vueApp.component('CDropdown', CDropdown);
  nuxtApp.vueApp.component('CIcon', CIcon);
  nuxtApp.vueApp.component('CSpinner', CSpinner);
  nuxtApp.vueApp.component('CTextbox', CTextbox);
});
