import {DateTime, Settings} from 'luxon';
import {defineNuxtPlugin} from '#imports';

export default defineNuxtPlugin(() => {
  const luxon = (date, formatOpts) => {
    if (!date) {
      return null;
    }

    if (!formatOpts) {
      formatOpts = DateTime.DATE_FULL;
    }

    Settings.defaultLocale = 'en-US';

    return DateTime.fromISO(date).toLocaleString(formatOpts);
  };

  return {
    provide: {
      luxon,
    },
  };
});
