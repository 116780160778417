import { default as changelogr6wFZyLtGkMeta } from "/vercel/path0/src/pages/[handle]/changelog.vue?macro=true";
import { default as editions3uqLc4F8GiMeta } from "/vercel/path0/src/pages/[handle]/editions.vue?macro=true";
import { default as indextb9yzeMC7HMeta } from "/vercel/path0/src/pages/[handle]/index.vue?macro=true";
import { default as reviewsBVxE7pXkUZMeta } from "/vercel/path0/src/pages/[handle]/reviews.vue?macro=true";
import { default as _91slug_93abzygz8yYRMeta } from "/vercel/path0/src/pages/categories/[slug].vue?macro=true";
import { default as indexyp4HJf5455Meta } from "/vercel/path0/src/pages/developer/[slug]/index.vue?macro=true";
import { default as indexFCqpfHGhxoMeta } from "/vercel/path0/src/pages/featured/[id]/index.vue?macro=true";
import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as indexUBkpGYjKtPMeta } from "/vercel/path0/src/pages/search/index.vue?macro=true";
export default [
  {
    name: changelogr6wFZyLtGkMeta?.name ?? "handle-changelog",
    path: changelogr6wFZyLtGkMeta?.path ?? "/:handle()/changelog",
    meta: changelogr6wFZyLtGkMeta || {},
    alias: changelogr6wFZyLtGkMeta?.alias || [],
    redirect: changelogr6wFZyLtGkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/[handle]/changelog.vue").then(m => m.default || m)
  },
  {
    name: editions3uqLc4F8GiMeta?.name ?? "handle-editions",
    path: editions3uqLc4F8GiMeta?.path ?? "/:handle()/editions",
    meta: editions3uqLc4F8GiMeta || {},
    alias: editions3uqLc4F8GiMeta?.alias || [],
    redirect: editions3uqLc4F8GiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/[handle]/editions.vue").then(m => m.default || m)
  },
  {
    name: indextb9yzeMC7HMeta?.name ?? "handle",
    path: indextb9yzeMC7HMeta?.path ?? "/:handle()",
    meta: indextb9yzeMC7HMeta || {},
    alias: indextb9yzeMC7HMeta?.alias || [],
    redirect: indextb9yzeMC7HMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/[handle]/index.vue").then(m => m.default || m)
  },
  {
    name: reviewsBVxE7pXkUZMeta?.name ?? "handle-reviews",
    path: reviewsBVxE7pXkUZMeta?.path ?? "/:handle()/reviews",
    meta: reviewsBVxE7pXkUZMeta || {},
    alias: reviewsBVxE7pXkUZMeta?.alias || [],
    redirect: reviewsBVxE7pXkUZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/[handle]/reviews.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93abzygz8yYRMeta?.name ?? "categories-slug",
    path: _91slug_93abzygz8yYRMeta?.path ?? "/categories/:slug()",
    meta: _91slug_93abzygz8yYRMeta || {},
    alias: _91slug_93abzygz8yYRMeta?.alias || [],
    redirect: _91slug_93abzygz8yYRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyp4HJf5455Meta?.name ?? "developer-slug",
    path: indexyp4HJf5455Meta?.path ?? "/developer/:slug()",
    meta: indexyp4HJf5455Meta || {},
    alias: indexyp4HJf5455Meta?.alias || [],
    redirect: indexyp4HJf5455Meta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/developer/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFCqpfHGhxoMeta?.name ?? "featured-id",
    path: indexFCqpfHGhxoMeta?.path ?? "/featured/:id()",
    meta: indexFCqpfHGhxoMeta || {},
    alias: indexFCqpfHGhxoMeta?.alias || [],
    redirect: indexFCqpfHGhxoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/featured/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkP7wmsbr11Meta?.name ?? "index",
    path: indexkP7wmsbr11Meta?.path ?? "/",
    meta: indexkP7wmsbr11Meta || {},
    alias: indexkP7wmsbr11Meta?.alias || [],
    redirect: indexkP7wmsbr11Meta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexUBkpGYjKtPMeta?.name ?? "search",
    path: indexUBkpGYjKtPMeta?.path ?? "/search",
    meta: indexUBkpGYjKtPMeta || {},
    alias: indexUBkpGYjKtPMeta?.alias || [],
    redirect: indexUBkpGYjKtPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/search/index.vue").then(m => m.default || m)
  }
]