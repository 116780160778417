import axios from 'axios';
import {useEnv} from '../helpers/env';

// Create the abort controller
let controller = null;

if (process.client) {
  controller = new AbortController();
}
const {apiEndpointUrl} = useEnv();

// Create the axios instance
const _axios = axios.create({
  baseURL: apiEndpointUrl,
  signal: controller ? controller.signal : null,
});

export default {
  cancelRequests() {
    if (process.client && controller) {
      // Abort requests
      controller.abort();

      // Create new abort controller
      controller = new AbortController();
      _axios.defaults.signal = controller.signal;
    }
  },

  getCoreData() {
    return new Promise((resolve, reject) => {
      _axios
        .get('plugin-store/core-data')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            reject(error);
          }
        });
    });
  },

  getDeveloperBySlug(slug) {
    return new Promise((resolve, reject) => {
      _axios
        .get('developer/' + slug)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            reject(error);
          }
        });
    });
  },

  getFeaturedSectionByHandle(featuredSectionHandle) {
    return new Promise((resolve, reject) => {
      _axios
        .get('plugin-store/featured-section/' + featuredSectionHandle)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            reject(error);
          }
        });
    });
  },

  getFeaturedSections({cmsConstraint}) {
    return new Promise((resolve, reject) => {
      _axios
        .get('plugin-store/featured-sections', {
          params: {
            cmsConstraint,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            reject(error);
          }
        });
    });
  },

  getPluginChangelog({cmsConstraint, pluginId}) {
    return new Promise((resolve, reject) => {
      _axios
        .get('plugin/' + pluginId + '/changelog', {
          params: {
            cmsConstraint,
          },
        })
        .then((response) => {
          if (
            response.data &&
            response.data.error &&
            response.data.error === "Couldn't find plugin"
          ) {
            // The plugin changelog hasn’t been found using the `cmsConstraint`, try again without
            _axios
              .get('plugin/' + pluginId + '/changelog')
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                if (axios.isCancel(error)) {
                  // request cancelled
                } else {
                  reject(error);
                }
              });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            reject(error);
          }
        });
    });
  },

  getPluginDetails(pluginId) {
    return new Promise((resolve, reject) => {
      _axios
        .get('plugin/' + pluginId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            reject(error);
          }
        });
    });
  },

  getPluginDetailsByHandle({cmsConstraint, pluginHandle}) {
    return new Promise((resolve, reject) => {
      _axios
        .get('plugin-store/plugin/' + pluginHandle, {
          params: {
            cmsConstraint,
            withInstallHistory: true,
            withIssueStats: true,
            withReviewStats: true,
          },
        })
        .then((response) => {
          if (
            response.data &&
            response.data.error &&
            response.data.error === "Couldn't find plugin"
          ) {
            // The plugin details haven’t been found using the `cmsConstraint`, try again without
            _axios
              .get('plugin-store/plugin/' + pluginHandle, {
                params: {
                  withInstallHistory: true,
                  withIssueStats: true,
                },
              })
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                if (axios.isCancel(error)) {
                  // request cancelled
                } else {
                  reject(error);
                }
              });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            reject(error);
          }
        });
    });
  },
};
