import type {LocationQuery} from "vue-router";
import {defineStore} from 'pinia';
import {usePluginIndexStore, usePluginStoreStore} from '#imports';

interface PageMeta {
  description: string | null;
  title: string | null;
}

export const useAppStore = defineStore('app', {
  state: () => ({
    /**
     * Supported Craft CMS versions.
     */
    supportedCmsVersions: [
      {
        version: 5,
        cmsConstraint: '^5.0',
        alias: 'craft5',
      },
      {
        version: 4,
        cmsConstraint: '^4.0',
        alias: 'craft4',
      },
      {
        version: 3,
        cmsConstraint: '^3.0',
        alias: 'craft3',
      },
    ],
    /**
     * Default CMS constraint.
     */
    cmsConstraint: '^5.0',

    /**
     * Other state variables.
     */
    pageMeta: null as PageMeta | null,
    showingNavigation: false,
    searchQuery: '',
    showingScreenshotModal: false,
    screenshotModalImages: null as any[] | null,
    screenshotModalImageKey: 0,
  }),
  getters: {
    /**
     * Returns an object containing the CMS version alias as a key (e.g. {craft4: null}), aimed to be used as a query params object.
     */
    getCmsConstraintQuery(state) {
      return (query: {[key: string]: string | number | null} = {}) => {
        query = Object.assign({}, query);

        // Remove CMS version related query params
        state.supportedCmsVersions.forEach((cms) => {
          for (const property in query) {
            if (property === cms.alias) {
              delete query[property];
            }
          }
        });

        // Add CMS version related query param
        for (const cmsVersion of state.supportedCmsVersions) {
          if (cmsVersion.cmsConstraint === state.cmsConstraint) {
            query[cmsVersion.alias] = null;
            break;
          }
        }

        return query;
      };
    },

    /**
     * Returns the CMS constraint for a given query params object.
     */
    getCmsConstraintFromQuery(state) {
      return (query: LocationQuery) => {
        for (const cmsVersion of state.supportedCmsVersions) {
          if (query[cmsVersion.alias] !== undefined) {
            return cmsVersion.cmsConstraint;
          }
        }

        return null;
      };
    },

    /**
     * Return the CMS constraint select options.
     */
    getCmsConstraintSelectOptions(state) {
      return state.supportedCmsVersions.map((cms) => {
        return {
          label: `Craft ${cms.version} Plugin Store`,
          value: cms.cmsConstraint,
        };
      });
    },
  },
  actions: {
    cancelRequests() {
      const pluginStoreStore = usePluginStoreStore();
      const pluginIndexStore = usePluginIndexStore();

      pluginStoreStore.cancelRequests();
      pluginIndexStore.cancelRequests();
    },
    toggleNavigation() {
      this.showingNavigation = !this.showingNavigation;
    },
    toggleScreenshotModal() {
      this.showingScreenshotModal = !this.showingScreenshotModal;
    },
    hideNavigation() {
      this.showingNavigation = false;
    },
    updateSearchQuery(searchQuery: string) {
      this.searchQuery = searchQuery;
    },
    updateShowingScreenshotModal(show: boolean) {
      this.showingScreenshotModal = show;
    },
    updateScreenshotModalImages(images: any[] | null) {
      this.screenshotModalImages = images;
    },
    updateScreenshotModalImageKey(key: number) {
      this.screenshotModalImageKey = key;
    },
    updatePageMeta(pageMeta: PageMeta | null) {
      this.pageMeta = pageMeta;
    },
    setCmsConstraint(cmsConstraint: string) {
      this.cmsConstraint = cmsConstraint;
    },
  },
});
