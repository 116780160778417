import Book from './BookIcon.vue';
import Check from './CheckIcon.vue';
import ChevronLeft from './ChevronLeftIcon.vue';
import ChevronRight from './ChevronRightIcon.vue';
import ClipboardCopy from './ClipboardCopyIcon.vue';
import ExclamationCircle from './ExclamationCircleIcon.vue';
import ExternalLink from './ExternalLinkIcon.vue';
import InformationCircle from './InformationCircleIcon.vue';
import Link from './LinkIcon.vue';
import Menu from './MenuIcon.vue';
import Search from './SearchIcon.vue';
import Star from './StarIcon.vue';
import World from './WorldIcon.vue';
import X from './XIcon.vue';

export default {
  book: Book,
  check: Check,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'clipboard-copy': ClipboardCopy,
  'exclamation-circle': ExclamationCircle,
  'external-link': ExternalLink,
  'information-circle': InformationCircle,
  link: Link,
  menu: Menu,
  search: Search,
  star: Star,
  world: World,
  x: X,
};
