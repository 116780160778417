import {defineStore} from 'pinia';
import api from '~/api/pluginIndex';
import {useAppStore, usePluginStoreStore} from '#imports';

export const usePluginIndexStore = defineStore('pluginIndex', {
  state: () => ({
    pluginsResponseData: null,
    plugins: [],
  }),
  getters: {
    hasMore(state) {
      if (!state.pluginsResponseData) {
        return false;
      }
      return (
        state.pluginsResponseData.currentPage < state.pluginsResponseData.total
      );
    },

    getPluginIndexParams() {
      return (context) => {
        const pluginStoreStore = usePluginStoreStore();
        const appStore = useAppStore();

        const sortOptions = pluginStoreStore.sortOptions;
        const firstOptionKey = Object.keys(sortOptions)[0];

        const cmsConstraint = appStore.cmsConstraint;
        const perPage = context.perPage ? context.perPage : null;
        const page = context.page ? context.page : 1;
        const orderBy = context.disableSorting
          ? null
          : context.orderBy
            ? context.orderBy
            : firstOptionKey;
        const direction = context.disableSorting
          ? null
          : context.direction
            ? context.direction
            : pluginStoreStore.sortOptions[firstOptionKey];

        return {
          cmsConstraint,
          perPage,
          page,
          orderBy,
          direction,
        };
      };
    },
  },
  actions: {
    cancelRequests() {
      return new Promise((resolve) => {
        api.cancelRequests();
        resolve();
      });
    },

    getPluginsByCategory(context) {
      return new Promise((resolve, reject) => {
        const pluginIndexParams = this.getPluginIndexParams(context);
        api
          .getPluginsByCategory({
            categoryId: context.categoryId,
            pluginIndexParams,
          })
          .then((response) => {
            if (response.data && response.data.error) {
              reject(response.data.error);
            }

            this.updatePluginIndex({context, response}).then(() => {
              resolve(response);
            });
          })
          .catch((thrown) => {
            if (thrown.response && thrown.response.data) {
              if (thrown.response.data.message) {
                reject(thrown.response.data.message);
              } else if (thrown.response.data.error) {
                reject(thrown.response.data.error);
              } else {
                reject(thrown.response.data);
              }
            } else {
              reject(thrown);
            }
          });
      });
    },

    getPluginsByDeveloperId(context) {
      return new Promise((resolve, reject) => {
        const pluginIndexParams = this.getPluginIndexParams(context);

        api
          .getPluginsByDeveloperId({
            developerId: context.developerId,
            pluginIndexParams,
          })
          .then((response) => {
            if (response.data && response.data.error) {
              reject(response.data.error);
            }

            this.updatePluginIndex({context, response}).then(() => {
              resolve(response);
            });
          })
          .catch((thrown) => {
            if (thrown.response && thrown.response.data) {
              if (thrown.response.data.message) {
                reject(thrown.response.data.message);
              } else if (thrown.response.data.error) {
                reject(thrown.response.data.error);
              } else {
                reject(thrown.response.data);
              }
            } else {
              reject(thrown);
            }
          });
      });
    },

    getPluginsByFeaturedSectionHandle(context) {
      return new Promise((resolve, reject) => {
        const pluginIndexParams = this.getPluginIndexParams(context);

        return api
          .getPluginsByFeaturedSectionHandle({
            featuredSectionHandle: context.featuredSectionHandle,
            pluginIndexParams,
          })
          .then((response) => {
            if (response.data && response.data.error) {
              reject(response.data.error);
            }

            this.updatePluginIndex({context, response}).then(() => {
              resolve(response);
            });
          })
          .catch((thrown) => {
            if (thrown.response && thrown.response.data) {
              if (thrown.response.data.message) {
                reject(thrown.response.data.message);
              } else if (thrown.response.data.error) {
                reject(thrown.response.data.error);
              } else {
                reject(thrown.response.data);
              }
            } else {
              reject(thrown);
            }
          });
      });
    },

    searchPlugins(context) {
      return new Promise((resolve, reject) => {
        const pluginIndexParams = this.getPluginIndexParams(context);

        api
          .searchPlugins({
            searchQuery: context._searchQuery,
            pluginIndexParams,
          })
          .then((response) => {
            if (response.data && response.data.error) {
              reject(response.data.error);
            }

            this.updatePluginIndex({context, response}).then(() => {
              resolve(response);
            });
          })
          .catch((thrown) => {
            if (thrown.response && thrown.response.data) {
              if (thrown.response.data.message) {
                reject(thrown.response.data.message);
              } else if (thrown.response.data.error) {
                reject(thrown.response.data.error);
              } else {
                reject(thrown.response.data);
              }
            } else {
              reject(thrown);
            }
          });
      });
    },

    async updatePluginIndex({context, response}) {
      this.updatePluginsResponseData(response);

      if (context.appendData) {
        this.appendPlugins(response.data.plugins);
      } else {
        // Using a promise to wrap the timeout to await its completion
        await new Promise((resolve) => setTimeout(() => resolve(), 1));
        this.updatePlugins(response.data.plugins);
      }
    },

    updatePlugins(plugins) {
      this.plugins = plugins;
    },

    updatePluginsResponseData(response) {
      this.pluginsResponseData = response.data;
    },

    appendPlugins(plugins) {
      this.plugins = [...this.plugins, ...plugins];
    },
  },
});
