<template>
  <component
    :is="component"
    class="c-btn truncate"
    :to="to"
    :href="href"
    :target="target"
    :type="computedType"
    :class="[
      {
        small,
        large,
        block,
        outline,
        loading,
        [kind]: true,
        'c-btn-icon': icon && !$slots.default,
        group: true,

        // Base
        'inline-block px-4 py-2 rounded-md': true,
        'text-sm leading-5 no-underline font-medium': true,
        'border border-solid': true,
        'disabled:opacity-50 disabled:cursor-default': true,
        'focus:outline-none focus:ring-[3px]': true,
        'text-center': block,
        'shadow-sm': !disableShadow,

        // Variants
        'text-white dark:text-white': kind === 'primary' && !outline,
        'hover:text-white dark:hover:text-white':
          kind === 'primary' && !outline,
        'active:text-white dark:active:text-white':
          kind === 'primary' && !outline,

        // Default
        'text-interactive': kind === 'default',

        // Primary
        'border-blue-500 dark:border-blue-600': kind === 'primary',
        'bg-blue-500 dark:bg-blue-600': kind === 'primary' && !outline,
        'hover:bg-blue-700 hover:border-blue-700 active:hover:bg-blue-500 active:hover:border-blue-500':
          kind === 'primary' && !outline,
        'active:bg-blue-800 dark:active:bg-blue-700 active:border-blue-800 dark:active:border-blue-700':
          kind === 'primary' && !outline,
        'disabled:bg-blue-500 dark:disabled:bg-blue-600 disabled:border-blue-500 dark:disabled:border-blue-600':
          kind === 'primary' && !outline,
        'shadow-blue-600/30': kind === 'primary' && !outline,
        'text-blue-500 dark:text-blue-600': kind === 'primary' && outline,
        'hover:bg-blue-500 dark:hover:bg-blue-600':
          kind === 'primary' && outline,
        'active:bg-blue-800 dark:active:bg-blue-700':
          kind === 'primary' && outline,

        // Secondary
        'border-gray-200 dark:border-gray-600 text-interactive':
          kind === 'secondary',
        'hover:cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-200 dark:hover:border-gray-600 hover:no-underline':
          kind === 'secondary',
        'active:cursor-pointer active:bg-gray-100 dark:active:bg-gray-600 active:border-gray-300 dark:active:border-gray-600':
          kind === 'secondary',
        'bg-white dark:bg-gray-800': kind === 'secondary' && !outline,
        'text-interactive': kind === 'secondary' && !outline,
        'shadow-gray-600/7': kind === 'secondary' && !outline,

        // Danger
        'text-red-500 dark:border-gray-600 bg-white dark:bg-gray-700 focus:border-red-400 focus:ring-red-500/30':
          kind === 'danger',
        'hover:bg-red-500 hover:text-white dark:hover:text-white hover:border-red-600 dark:hover:border-red-400':
          kind === 'danger',
        'active:bg-red-600': kind === 'danger',
        'shadow-gray-600/7 hover:shadow-red-600/30': kind === 'danger',
      },
    ]"
    v-bind="additionalAttributes"
    @click="$emit('click')"
  >
    <template v-if="loading">
      <c-spinner
        :animation-class="`border-${animationColor} group-hover:border-${animationColorHover}`"
      />
    </template>

    <div class="c-btn-content">
      <c-icon v-if="icon && icon.length > 0" :icon="icon" size="sm" />

      <slot />

      <c-icon
        v-if="trailingIcon && trailingIcon.length > 0"
        class="ml-1"
        :icon="trailingIcon"
        size="sm"
      />
    </div>
  </component>
</template>

<script>
  export default {
    name: 'BtnComponent',
    props: {
      /**
       * 'button', 'submit', 'reset', or 'menu'
       */
      type: {
        type: String,
        default: 'button',
      },
      /**
       * 'default', 'primary', or 'danger'
       */
      kind: {
        type: String,
        default: 'secondary',
      },
      /**
       * Smaller version of button if set to `true`.
       */
      small: {
        type: Boolean,
        default: false,
      },
      /**
       * Larger version of button if set to `true`.
       */
      large: {
        type: Boolean,
        default: false,
      },
      /**
       * Block version of button if set to `true`.
       */
      block: {
        type: Boolean,
        default: false,
      },
      /**
       * Disabled version of button if set to `true`.
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Outline version of button if set to `true`.
       */
      outline: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: [String, Array],
        default: null,
      },
      trailingIcon: {
        type: String,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      to: {
        type: String,
        default: null,
      },
      href: {
        type: String,
        default: null,
      },
      target: {
        type: String,
        default: null,
      },
      disableShadow: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['click'],

    computed: {
      additionalAttributes() {
        const attrs = {};

        if (this.disabled) {
          attrs.disabled = true;
        }

        return attrs;
      },

      component() {
        if (this.to !== null && this.to !== '') {
          return 'router-link';
        }

        if (this.href !== null && this.href !== '') {
          return 'a';
        }

        return 'button';
      },

      computedType() {
        if (this.to !== null || this.href !== null) {
          return null;
        }

        return this.type;
      },

      animationColor() {
        return this.kind === 'secondary'
          ? 'interactive'
          : !this.outline
            ? 'text-inverse'
            : 'interactive-' + this.kind;
      },

      animationColorHover() {
        return this.kind === 'secondary' ? 'interactive' : 'text-inverse';
      },
    },
  };
</script>

<style lang="scss">
  .c-btn,
  a.c-btn,
  button.c-btn {
    &.block {
      @apply w-full;
      display: block;
    }

    &.small {
      @apply px-3 leading-4;

      .c-icon {
        width: 12px;
        height: 12px;
      }
    }

    &.large {
      @apply text-base leading-6;
    }

    &.outline {
      .c-icon {
        @apply fill-current;
      }
    }

    &.loading {
      @apply relative;

      .c-spinner {
        @apply absolute inset-0 flex justify-center items-center;
      }

      .c-btn-content {
        @apply invisible;
      }
    }

    .c-icon {
      @apply align-middle;
    }

    &:not(.c-btn-icon) {
      .c-icon {
        /*@include mr(1);*/
      }
    }

    .c-btn-content {
      @apply flex items-center justify-center;
    }
  }
</style>
