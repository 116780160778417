import axios from 'axios';
import {getElementIndexParams} from '../helpers/elementIndex.js';
import {useEnv} from '../helpers/env';

// Create the abort controller
let controller = null;

if (process.client) {
  controller = new AbortController();
}

const {apiEndpointUrl} = useEnv();

// Create the axios instance
const _axios = axios.create({
  baseURL: apiEndpointUrl,
  signal: controller ? controller.signal : null,
});

if (import.meta.env.DEV) {
  _axios.interceptors.request.use((config) => {
    /* eslint-disable */
    console.groupCollapsed('Making request');
    console.log(`${config.baseURL}/${config.url}`);
    console.log(config.params);
    console.groupEnd();
    /* eslint-enable */
    return config;
  });
}

export default {
  cancelRequests() {
    if (process.client && controller) {
      // Abort requests
      controller.abort();

      // Create new abort controller
      controller = new AbortController();
      _axios.defaults.signal = controller.signal;
    }
  },

  getPluginsByCategory({categoryId, pluginIndexParams}) {
    return new Promise((resolve, reject) => {
      const params = this._getPluginIndexParams(pluginIndexParams);
      params.categoryId = categoryId;

      _axios
        .get('plugin-store/plugins', {
          params,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            reject(error);
          }
        });
    });
  },

  getPluginsByDeveloperId({developerId, pluginIndexParams}) {
    return new Promise((resolve, reject) => {
      const params = this._getPluginIndexParams(pluginIndexParams);
      params.developerId = developerId;

      _axios
        .get('plugin-store/plugins', {
          params,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            reject(error);
          }
        });
    });
  },

  getPluginsByFeaturedSectionHandle({
    featuredSectionHandle,
    pluginIndexParams,
  }) {
    return new Promise((resolve, reject) => {
      _axios
        .get(
          'plugin-store/plugins-by-featured-section/' + featuredSectionHandle,
          {
            params: this._getPluginIndexParams(pluginIndexParams),
          },
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            reject(error);
          }
        });
    });
  },

  _getPluginIndexParams({cmsConstraint, perPage, page, orderBy, direction}) {
    return {
      cmsConstraint,
      ...getElementIndexParams({perPage, page, orderBy, direction}),
    };
  },

  searchPlugins({searchQuery, pluginIndexParams}) {
    return new Promise((resolve, reject) => {
      const params = this._getPluginIndexParams(pluginIndexParams);
      params.searchQuery = searchQuery;

      _axios
        .get('plugin-store/plugins', {
          params,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            reject(error);
          }
        });
    });
  },
};
